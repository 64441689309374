import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles(() => ({
  modalContent: {
    borderRadius: rem(20),
    minWidth: 'fit-content',
  },
  modaBody: {
    display: 'flex',
    padding: rem(24),
  },
}));
