import React, { useCallback, useState } from 'react';
import {
  Box,
  Center,
  Flex,
  Group,
  Modal,
  Progress,
  rem,
  Stack,
} from '@mantine/core';
import dayjs from 'dayjs';
import BackNagination from '../../components/BackNavigation/BackNagination';
import MainButton from '../../components/buttons/buttons';
import Container from '../../components/Container/Container';
import TimePeriodSelection from '../../components/filtering/TimePeriodSelection/TimePeriodSelection';
import DownloadsMatchesFlow from '../../components/Modals/DownloadsMatchesFlow';
import StatsChart from '../../components/StatsChart/StatsChart';
import TabsWithCards from '../../components/TabsWithCards/TabsWithCards';
import CustomText from '../../components/Typography/CustomText/CustomText';
import useURLParams from '../../hooks/useURLParams';
import CustomTitle from '../../components/Typography/CustomTitle/CustomTitle';
import { useStyle } from './TopicsDetailPage.style';
import GuidedTour from '../../components/GuidedTour/GuidedTour';
import { useDispatch, useSelector } from 'react-redux';
import GuidedTourModal from '../../components/GuidedTour/GuidedTourModal/GuidedTourModal';
import { setGuide } from '../../features/profileSlice';
import useGuideModal from '../../hooks/useGuideModal';
import { getTopicDetailsSteps } from '../../features/guidedTourSteps/topicDetailsPage';
import EnrichmentAction from '../../components/BlurredLayout/actions/EnrichmentAction';
import useRadarApi from '../../hooks/useRadarApi';
import useJwtToken from '../../hooks/useJwtToken';
import useTabsWithContent from '../../hooks/useTabsWithContent';
import { useParams } from 'react-router-dom';
import useFetchTabsWithContent from '../../hooks/useFetchTabsWithContent';
import { getBackUrl, getFromEngagmentRange } from '../../utils';
import SkeletonContainer from '../../components/SkeletonContainer';
import OtherAudienceInterests from './components/OtherAudienceInterests/OtherAudienceInterests';
import PeopleAlsoAsk from './components/PeopleAlsoAsk/PeopleAlsoAsk';
import useFetchTopicsDetails from './hooks/useFetchTopicsDetails';
import useChartMetrics from './hooks/useChartMetrics';
import useTopicCSV from './hooks/useTopicCSV';
import useEnrichTopic from './hooks/useEnrichTopic';
import { DEFAULT_DATE_FILTER_PARAMS } from '../../components/filtering/TimePeriodSelection/constants';
import useNavigateWithState from '../../hooks/useNavigateWithState';
// import BlurredLayout from '../../components/BlurredLayout/BlurredLayout';
// import ConnectAnalyticsAction from '../../components/BlurredLayout/actions/ConnectAnalyticsAction';

const TABS = {
  CONTENT: 'Your Coverage',
  ORGANIC: 'Organic search',
  // NEWS: 'News',
  COMPETITOR: 'Competitors',
};

const tabNames = Object.keys(TABS);
const tabValues = Object.values(TABS);
const defaultTab = tabValues[0];

const DEFAULT_DATE_FILTER = {
  ...DEFAULT_DATE_FILTER_PARAMS,
  tab: tabValues[0],
};
const PARAMS_KEYS = ['dateFilter', 'tab'];

const DATE_LIST = [
  '2023-02-18T07:21:12.000Z',
  '2023-02-19T07:21:12.000Z',
  '2023-02-20T07:21:12.000Z',
  '2023-02-21T07:21:12.000Z',
  '2023-02-22T07:21:12.000Z',
  '2023-02-23T07:21:12.000Z',
  '2023-02-24T07:21:12.000Z',
];

function TopicsDetailPage() {
  const [improveScoreModal, setImproveScoreModal] = useState(false);
  const [activeTab, setActiveTab] = useState(defaultTab);

  const { showGuide, stepIndex, guideModalIndex } = useSelector(
    (state) => state.profile.guide.topicDetails,
  );
  const dispatch = useDispatch();
  const navigateWithState = useNavigateWithState();
  const { handleGuideModalClick } = useGuideModal('topicDetails', stepIndex);
  const { classes } = useStyle();

  const { loading } = useJwtToken();
  const { topicId } = useParams();
  const {
    getTopicInterestedContacts,
    getTopicEngagementRate,
    getTopicCoverageScore,
    getTopicDetailContent,
    getTopicOtherAudienceInterests,
    getTopicPeopleAlsoAsk,
    getTopicContactMatches,
    getTopicValue,
  } = useRadarApi();

  const { urlDateParams, urlState, updateDateParam, getValidatedDateFilter } =
    useURLParams(PARAMS_KEYS, DEFAULT_DATE_FILTER);

  const enrichAction = useEnrichTopic(topicId);
  const fetchContent = useFetchTabsWithContent(topicId, getTopicDetailContent);
  const [relatedContentData] = useTabsWithContent(
    tabValues,
    tabNames,
    tabValues.findIndex((el) => el === activeTab),
    (params) => fetchContent(params),
    // eslint-disable-next-line react/jsx-key
    [
      null,
      {
        element: <EnrichmentAction onSuccess={enrichAction} />,
        hideMatches: true,
      },
      { hideMatches: true },
      { hideMatches: true },
    ],
  );
  const [topicInterestedContacts, topicInterestedContactsLoading] =
    useFetchTopicsDetails(
      loading,
      topicId,
      getTopicInterestedContacts,
      getValidatedDateFilter(),
    );
  const [topicEngagementRate, topicEngagementRateLoading] =
    useFetchTopicsDetails(
      loading,
      topicId,
      getTopicEngagementRate,
      getValidatedDateFilter(),
    );
  const [topicCoverageScore, topicCoverageScoreLoading] = useFetchTopicsDetails(
    loading,
    topicId,
    getTopicCoverageScore,
  );
  const [topicValue, topicValueLoading] = useFetchTopicsDetails(
    loading,
    topicId,
    getTopicValue,
  );

  const [topicOtherAudienceInterests, topicOtherAudienceInterestsLoading] =
    useFetchTopicsDetails(loading, topicId, getTopicOtherAudienceInterests);
  const [topicPeopleAlsoAsk, topicPeopleAlsoAskLoading] = useFetchTopicsDetails(
    loading,
    topicId,
    getTopicPeopleAlsoAsk,
  );
  const [topicContactMatches, topicContactMatchesLoading] =
    useFetchTopicsDetails(loading, topicId, getTopicContactMatches);

  const [getChartLabels, getChartData, score] =
    useChartMetrics(topicCoverageScore);
  const [onLinkedinExportClick, onFullReportExportClick] =
    useTopicCSV(topicContactMatches);

  const handleBackNavigation = useCallback(() => {
    dispatch(
      setGuide({
        key: 'topics',
        value: {
          guideModalIndex: 1,
        },
      }),
    );
    dispatch(
      setGuide({
        key: 'topicDetails',
        value: {
          showGuide: false,
        },
      }),
    );
  }, [dispatch]);

  const handleContentClick = useCallback(
    (data, preventAction) => {
      preventAction();
      if (activeTab === tabValues[0]) {
        navigateWithState(`/contents/detail/${data?.id}`);
      } else if (activeTab === tabValues[1]) {
        window.open(data?.url, '_blank');
      } else {
        navigateWithState(`/competitors/content/${data?.id}`);
      }
    },
    [activeTab],
  );

  return (
    <Box>
      <Flex justify="space-between" align="center" mb={rem(19)}>
        <BackNagination
          title="Back to Topics"
          href={getBackUrl(urlState?.prevUrl, '/topics')}
          onClick={handleBackNavigation}
        />
        <Group spacing={32}>
          <Box mb={-16}>
            <TimePeriodSelection
              updateDateFilter={updateDateParam}
              initialFetchPeriodKey={urlDateParams?.dateKey}
            />
          </Box>
          <DownloadsMatchesFlow
            onFullReportExportClick={onFullReportExportClick}
            onLinkedinExportClick={onLinkedinExportClick}
            disabled={
              topicContactMatchesLoading || !topicContactMatches?.length
            }
          />
        </Group>
      </Flex>
      <Center>
        <Stack spacing={19}>
          {topicValueLoading ? (
            <SkeletonContainer width={250} height={25} radius={8} />
          ) : (
            <CustomText variant="h3">{topicValue?.value}</CustomText>
          )}
          <Group spacing={24} sx={{ flexWrap: 'nowrap', marginTop: rem(-16) }}>
            <StatsChart
              title={topicInterestedContacts?.total || 0}
              subtitle="Interested contacts"
              statsValue={topicInterestedContacts?.changePercentage || 0}
              chartData={getChartData(topicInterestedContacts)}
              chartLabels={getChartLabels(topicInterestedContacts)}
              tooltipLabel=" Interested contacts"
              dateList={DATE_LIST}
              loading={topicInterestedContactsLoading}
              containerClassName={classes.containerClassName}
            />
            <Box className="tour-first">
              <StatsChart
                title={`${topicEngagementRate?.engagementCount || 0}`}
                subtitle="Engagement Count"
                statsValue={topicEngagementRate?.changePercentage || 0}
                chartData={getChartData(topicEngagementRate)}
                chartLabels={getChartLabels(topicEngagementRate)}
                tooltipLabel="% Engagement rate"
                dateList={DATE_LIST}
                loading={topicEngagementRateLoading}
                containerClassName={classes.containerClassName}
              />
            </Box>
            <Container
              className={classes.statsContainer}
              sx={{
                width: rem(365),
                height: rem(357),
                maxHeight: rem(357),
                padding: rem(16),
                marginTop: rem(16),
              }}
            >
              <Box>
                <Stack spacing={4}>
                  {topicCoverageScoreLoading ? (
                    <Stack spacing={8}>
                      <SkeletonContainer height={21} width={100} amount={1} />
                      <SkeletonContainer
                        height={13}
                        width={150}
                        radius={100}
                        amount={1}
                      />
                    </Stack>
                  ) : (
                    <>
                      <CustomText variant="h2">
                        {getFromEngagmentRange(score).title}
                      </CustomText>
                      <Progress
                        value={score}
                        color={getFromEngagmentRange(score).color}
                        radius={25}
                        styles={(theme) => ({
                          root: {
                            height: rem(12),
                            width: rem(120),
                            background: theme.colors.white[1],
                          },
                        })}
                      />
                    </>
                  )}
                </Stack>
                <CustomText
                  variant="h4"
                  sx={{ margin: `${rem(8)} 0 ${rem(16)}` }}
                >
                  Your coverage score breakdown
                </CustomText>
                <Stack spacing={4}>
                  <Flex
                    justify="space-between"
                    align="center"
                    className={classes.coverageScoreItem}
                  >
                    <CustomText variant="h5">Topic incidence:</CustomText>
                    <CustomText variant="body2">
                      {topicCoverageScoreLoading ? (
                        <SkeletonContainer height={21} width={70} />
                      ) : (
                        <>{topicCoverageScore?.incidence || 0}</>
                      )}
                    </CustomText>
                  </Flex>
                  <Flex
                    justify="space-between"
                    align="center"
                    className={classes.coverageScoreItem}
                  >
                    <CustomText variant="h5">
                      Most recent publish date:
                    </CustomText>
                    <CustomText variant="body2">
                      {topicCoverageScoreLoading ? (
                        <SkeletonContainer height={21} width={70} />
                      ) : (
                        <>
                          {dayjs(topicCoverageScore?.latestPublishDate).format(
                            'DD MMM YY',
                          )}
                        </>
                      )}
                    </CustomText>
                  </Flex>
                  <Stack spacing={4} sx={{ position: 'relative' }}>
                    {/* <BlurredLayout>
                    <ConnectAnalyticsAction
                      withoutImage
                      spacing={6}
                      description="Connect Google Analytics"
                    />
                  </BlurredLayout> */}
                    {/* <Flex
                    justify="space-between"
                    align="center"
                    className={classes.coverageScoreItem}
                  >
                    <CustomText variant="h5">Organic visits/mo:</CustomText>
                    <CustomText variant="body2">
                      {topicCoverageScoreLoading ? (
                        <SkeletonContainer height={21} width={70} />
                      ) : (
                        <>{topicCoverageScore?.organicVisitsPerMonth || 0}</>
                      )}
                    </CustomText>
                  </Flex>
                  <Flex
                    justify="space-between"
                    align="center"
                    className={classes.coverageScoreItem}
                  >
                    <CustomText variant="h5">Engagement rate:</CustomText>
                    <CustomText variant="body2">
                      {topicCoverageScoreLoading ? (
                        <SkeletonContainer height={21} width={70} />
                      ) : (
                        <>{topicCoverageScore?.engagementRate * 100 || 0}%</>
                      )}
                    </CustomText>
                  </Flex> */}
                  </Stack>
                </Stack>
              </Box>
              <Box>
                <CustomText
                  variant="h5"
                  onClick={() => setImproveScoreModal(true)}
                  sx={(theme) => ({
                    fontWeight: 500,
                    color: theme.colors.red[0],
                    textDecoration: 'underline',
                    textAlign: 'end',
                    marginTop: rem(16),
                    cursor: 'pointer',
                  })}
                >
                  How can I improve my score?
                </CustomText>
              </Box>
            </Container>
          </Group>
        </Stack>
      </Center>
      <Flex mt={32} mb={16}>
        <CustomText variant="h3">Content</CustomText>
      </Flex>
      <TabsWithCards
        tabs={tabValues}
        value={activeTab}
        onTabChange={setActiveTab}
        tabsData={relatedContentData}
        onClick={handleContentClick}
        withScroll
        tabMinWidth={168}
      />
      <Group mt={32} sx={{ flexWrap: 'nowrap' }}>
        <OtherAudienceInterests
          topicOtherAudienceInterests={topicOtherAudienceInterests}
          loading={topicOtherAudienceInterestsLoading}
        />
        <PeopleAlsoAsk
          topicPeopleAlsoAsk={topicPeopleAlsoAsk}
          loading={topicPeopleAlsoAskLoading}
        />
      </Group>
      <Modal
        opened={improveScoreModal}
        onClose={() => setImproveScoreModal(false)}
        centered
        withCloseButton={false}
        classNames={{
          content: classes.modalContent,
          body: classes.modaBody,
        }}
      >
        <Box>
          <CustomTitle
            order={1}
            sx={{ fontSize: rem(20), marginBottom: rem(16) }}
          >
            How can I improve my score?
          </CustomTitle>
          <Stack spacing={24} mb={24}>
            <Stack spacing={8}>
              <CustomText variant="body2">
                There are a few ways to improve your coverage score.
                <br />
                <br />
                First, if your coverage volume is low, it’s as simple as
                producing more content about this topic. Use the detailed topic
                report to find new angles to create new content about the topic.
                That might be driven by recent news results or looking at the
                “Other Audience Interests” to come up with great topic combos.
                <br />
                <br />
                Secondly, while one of the many benefits of personalization is
                recommending content from your archives, if it’s been a while
                since the last time you wrote about this topic, you may want to
                update one of your older pieces of content on this topic (which
                will also boost your SEO!).
              </CustomText>
            </Stack>
          </Stack>
          <MainButton
            onClick={() => setImproveScoreModal(false)}
            sx={{
              width: rem(203),
              margin: '0 auto',
            }}
          >
            Got it
          </MainButton>
        </Box>
      </Modal>
      {/* Guided Tour Components */}
      <GuidedTourModal
        opened={showGuide && guideModalIndex === 0}
        title="Topic report"
        onClick={handleGuideModalClick}
        onClose={handleGuideModalClick}
      >
        <CustomText variant="body2">
          Topic reports give you an in-depth view of each of your topics and how
          they are performing over time.
        </CustomText>
      </GuidedTourModal>
      <GuidedTour
        stepIndex={stepIndex}
        setStepIndex={handleGuideModalClick}
        run={showGuide && guideModalIndex === null}
        steps={getTopicDetailsSteps(dispatch)}
      />
    </Box>
  );
}

export default TopicsDetailPage;
