import { Box, Center, Group, Stack, UnstyledButton } from '@mantine/core';
import React, { useCallback, useState } from 'react';
import MainButton from '../../buttons/buttons';
import CustomText from '../../Typography/CustomText/CustomText';
import CustomTitle from '../../Typography/CustomTitle/CustomTitle';
import PlanList from '../../upgradePlanComponents/PlanList';
import { useStyle } from './PlanCard.style';
import BasicModal from '../../Modals/BasicModal';

function PlanCard({ planData, monthlyPeriod, onSelect }) {
  const { classes } = useStyle();
  const [confirmationModalOpened, setConfirmationModalOpened] = useState(false);

  const handleSelection = useCallback(
    (data) => {
      setConfirmationModalOpened(false);
      onSelect(data);
    },
    [onSelect],
  );

  return (
    <Box className={classes.planContainer}>
      <Stack spacing={32}>
        <Stack>
          <Stack spacing={8}>
            <Center>
              <CustomText variant="h3">{planData.title}</CustomText>
            </Center>
            <CustomText
              variant="body2"
              sx={{ textAlign: 'center', lineHeight: '130%' }}
            >
              {planData.description}
            </CustomText>
          </Stack>
          <Center sx={{ alignItems: 'flex-end' }}>
            <CustomTitle
              order={1}
              sx={(theme) => ({ color: theme.colors.red[0] })}
            >
              ${((planData.price * 100) / 100).toFixed(2)}
            </CustomTitle>
            <Box pb={5}>
              <CustomText
                variant="body2"
                sx={(theme) => ({ color: theme.colors.gray[4] })}
              >
                /{monthlyPeriod ? 'mo' : 'yr'}
              </CustomText>
            </Box>
          </Center>
          <Center mih={10}>
            {!planData.currentPlan && (
              <Box mt={-8}>
                <CustomText
                  variant="h6"
                  sx={(theme) => ({ color: theme.colors.gray[4] })}
                >
                  Billed {monthlyPeriod ? 'monthly' : 'yearly'}
                </CustomText>
              </Box>
            )}
          </Center>
        </Stack>
        <Center h={36}>
          {planData.currentPlan ? (
            <CustomText variant="body1">Your Current Plan</CustomText>
          ) : (
            <MainButton
              customClassName={classes.upgradeButton}
              onClick={() => setConfirmationModalOpened(true)}
            >
              {planData.downgradePlan ? 'Downgrade to' : 'Upgrade to'}{' '}
              {planData.title}
            </MainButton>
          )}
        </Center>
        <PlanList planList={planData.planList} />
      </Stack>
      <BasicModal
        withCloseButton={false}
        opened={confirmationModalOpened}
        onClose={() => setConfirmationModalOpened(false)}
      >
        <Stack spacing={18} w="100%" align="center">
          <CustomTitle order={1}>Are you sure you want to change plan?</CustomTitle>
          <Group grow>
            <MainButton
              onClick={() =>
                handleSelection({
                  ...planData,
                  period: monthlyPeriod ? 'month' : 'year',
                })
              }
              sx={{ width: 203 }}
            >
              <CustomText variant="body2">Continue</CustomText>
            </MainButton>
            <UnstyledButton
              className={classes.outlinedCancelButton}
              onClick={() => setConfirmationModalOpened(false)}
            >
              <CustomText variant="body2">Cancel</CustomText>
            </UnstyledButton>
          </Group>
        </Stack>
      </BasicModal>
    </Box>
  );
}

export default PlanCard;
