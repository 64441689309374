// hooks/useAuthenticationFlow.js
import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import useRadarApi from '../../../hooks/useRadarApi';
import useJwtToken from '../../../hooks/useJwtToken';
import { passportControlConfig } from '../../../config';
import logger from '../../../utils/logger';

const FLOW_OPTIONS = {
  TEST_FLIGHT: 'testFlight',
  CONTENT_UTILIZATION_CAMPAIGN: 'contentUtilizationCampaign',
};

const useAuthenticationFlow = ({
  firstName,
  lastName,
  email,
  orgName,
  orgDomain,
  flow,
  loginToExtractData,
  preserveLogin,
  emailVerification,
}) => {
  const { loginWithRedirect, isAuthenticated, logout } = useAuth0();
  const { createAccount } = useRadarApi();
  const { loading: isJwtLoading } = useJwtToken();

  // Logout user if preserveLogin is not true
  useEffect(() => {
    if (!isJwtLoading && preserveLogin !== 'true') {
      const { origin, pathname} = window.location;
      const urlParams = new URLSearchParams();

      urlParams.set('preserveLogin', 'true');
      urlParams.set('firstName', firstName);
      urlParams.set('lastName', lastName);
      urlParams.set('email', email);
      urlParams.set('orgName', orgName);
      urlParams.set('orgDomain', orgDomain);
      urlParams.set('flow', flow);

      const redirectUrl = `${origin}${pathname}?${urlParams.toString()}`;

      logger.debug('Logging out user', { preserveLogin, redirectUrl });

      logout({
        logoutParams: {
          returnTo: redirectUrl,
        },
      });
    }
  }, [isJwtLoading, preserveLogin, logout]);

  // Create account and redirect user to login
  useEffect(() => {
    if (emailVerification || loginToExtractData) return;
    if (isJwtLoading || isAuthenticated) return;
    if (!firstName || !lastName || !email || !orgName || !flow) return;

    const { pathname, search } = window.location;

    const createRedirectUrl = (useExistingSearchParams = true) => {
      let urlParams;

      if (useExistingSearchParams) {
        urlParams = new URLSearchParams(search);
      } else {
        urlParams = new URLSearchParams();
        urlParams.set('flow', flow);
      }

      urlParams.set('preserveLogin', 'true');

      return `${pathname}?${urlParams.toString()}`;
    };

    const handleRedirect = (useExistingSearchParams, delay = 0) => {
      const redirectUrl = createRedirectUrl(useExistingSearchParams);
      const redirectFunction = () =>
        logger.debug('Redirecting user to login', { redirectUrl }) ||
        loginWithRedirect({ appState: { returnTo: redirectUrl } });
      if (delay > 0) {
        setTimeout(redirectFunction, delay);
      } else {
        redirectFunction();
      }
    };

    const accountType =
      flow === FLOW_OPTIONS.TEST_FLIGHT
        ? 'TEST_FLIGHT'
        : 'CONTENT_UTILIZATION_CAMPAIGN';

    logger.debug('Creating account', { email, orgName, orgDomain, accountType });

    createAccount({
      firstName,
      lastName,
      email,
      orgName,
      orgDomain,
      type: accountType,
    })
      .then(() => {
        logger.debug('Account created successfully');
        handleRedirect(true, passportControlConfig.delayBeforeRedirection);
      })
      .catch((error) => {
        if (error?.statusCode === 409) {
          logger.error('Duplicate account error', { email, orgName, orgDomain });
          handleRedirect(false);
        } else {
          // TODO: Send user to an error page and record details in HubSpot
          logger.error('A non-conflict error has occurred');
        }
      });
  }, [
    isJwtLoading,
    isAuthenticated,
    email,
    orgName,
    orgDomain,
    flow,
    createAccount,
    loginWithRedirect,
    emailVerification,
    loginToExtractData,
  ]);
};

export default useAuthenticationFlow;
