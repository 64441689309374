// AuthenticationGuard.jsx
import { withAuthenticationRequired } from '@auth0/auth0-react';
import React from 'react';
import PageLoader from './PageLoader'; // Make sure you import PageLoader correctly

const AuthenticationGuard = ({ component: Component, children }) => {
  return <Component>{children}</Component>;
};

export default withAuthenticationRequired(AuthenticationGuard, {
  onRedirecting: () => (
    <div className="page-layout">
      <PageLoader />
    </div>
  ),
  ...(localStorage.getItem('skipPreviewPage') !== 'true'
    ? { returnTo: '/quick-start' }
    : {}),
});
