import { useCallback } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import useFetch from '../../../hooks/useFetch';
import useRadarApi from '../../../hooks/useRadarApi';
import useJwtToken from '../../../hooks/useJwtToken';

const useActivePlan = () => {
  const [activeSubscriptionPlan, setActiveSubscriptionPlan] = useState(null);
  const { jwtTokenLoading, orgId } = useJwtToken();
  const { getActiveSubscriptionPlan } = useRadarApi();
  const [fetchFunc, loading] = useFetch(getActiveSubscriptionPlan);

  const fetchWrapper = useCallback(async () => {
    const data = await fetchFunc(orgId);
    setActiveSubscriptionPlan(data);
  }, [fetchFunc, getActiveSubscriptionPlan, orgId, activeSubscriptionPlan]);

  useEffect(() => {
    if (!jwtTokenLoading && orgId) {
      fetchWrapper();
    }
  }, [jwtTokenLoading, orgId]);

  return [activeSubscriptionPlan, loading];
};

export default useActivePlan;
