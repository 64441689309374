import React, { useMemo, useState } from 'react';
import {
  Box,
  Center,
  Group,
  Modal,
  rem,
  Stack,
  UnstyledButton,
} from '@mantine/core';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { ReactComponent as SuccessIcon } from '../../assets/icons/success-icon.svg';
import { ReactComponent as LikeIcon } from '../../assets/icons/like-icon.svg';
import MainButton from '../buttons/buttons';
import CustomTitle from '../Typography/CustomTitle/CustomTitle';
import { useStyles } from './EnrichFlow.style';
import CustomText from '../Typography/CustomText/CustomText';
import useCredits from '../../hooks/useCredits';
import { useEffect } from 'react';
import useActivePlan from '../upgradePlanComponents/hooks/useActivePlan';

function EnrichFlow({ title, onSuccess, credits, remainingKey }) {
  const [activeSubscriptionPlan] = useActivePlan();
  const [activeModalIndex, setActiveModalIndex] = useState(null);
  const { classes, cx } = useStyles();
  const {
    linkedInEnrichmentScore,
    personalizationScore,
    topicReportScore,
    refetch,
  } = useCredits();

  const remainingCredits = useMemo(() => {
    const scores = {
      linkedInEnrichmentScore,
      personalizationScore,
      topicReportScore,
    };

    return scores[remainingKey];
  }, [
    remainingKey,
    linkedInEnrichmentScore,
    personalizationScore,
    topicReportScore,
  ]);

  const creditsLeft = useMemo(() => {
    if (
      activeSubscriptionPlan?.tier !== 'ECONOMY' &&
      !activeSubscriptionPlan?.trial
    )
      return true;
    return (remainingCredits || 0) - credits >= 0;
  }, [credits, remainingCredits, activeSubscriptionPlan]);

  useEffect(() => {
    if (activeModalIndex === 0) {
      refetch();
    }
  }, [activeModalIndex]);

  const modalTitle = useMemo(() => {
    if (remainingKey === 'linkedInEnrichmentScore') {
      return 'Socially Enrich';
    }
    return 'Organically Enrich';
  }, [remainingKey]);

  const enrichmentType = useMemo(() => {
    if (remainingKey === 'linkedInEnrichmentScore') {
      return 'social enrichment';
    }
    return 'organic enrichment';
  }, [remainingKey]);

  const remainingCreditsText = useMemo(() => {
    if (remainingCredits <= 0) {
      if (activeSubscriptionPlan?.tier) {
        return 'no credits remaining';
      }
      return 'zero credits remaining';
    }
    return `${remainingCredits || 0} credits remaning`;
  }, [activeSubscriptionPlan, credits, remainingCredits]);

  const chargeText = useMemo(() => {
    if (activeSubscriptionPlan?.trial) {
      return 'Please visit /plan to upgrade your subscription';
    }
    return 'It will incur an overage charge as per your plan subscription';
  }, [activeSubscriptionPlan]);

  return (
    <Box>
      <UnstyledButton
        className={cx(classes.loadInteractionsButton, {
          [classes.loadInteractionsButtonDisabled]: !credits,
        })}
        onClick={() => setActiveModalIndex(0)}
        disabled={!credits}
      >
        <Group spacing={8}>
          <LikeIcon style={{ width: rem(25), height: rem(25) }} />
          <CustomText variant="body2">{title}</CustomText>
        </Group>
      </UnstyledButton>
      <Modal
        opened={activeModalIndex === 0}
        onClose={() => setActiveModalIndex(null)}
        centered
        withCloseButton={false}
        classNames={{
          content: classes.modalContent,
          body: classes.modaBody,
        }}
      >
        <UnstyledButton
          className={classes.closeButton}
          onClick={() => setActiveModalIndex(null)}
        >
          <AiOutlineCloseCircle />
        </UnstyledButton>
        <Center sx={{ flexGrow: 2 }}>
          <Stack align="center">
            <CustomTitle order={1}>{modalTitle}</CustomTitle>
            <CustomText variant="body1" className={cx(classes.description)}>
              You have{' '}
              <span
                className={cx({
                  [classes.descriptionRed]: !creditsLeft,
                })}
              >
                {remainingCreditsText},
              </span>
              <br />
              This {enrichmentType} will use:
            </CustomText>
            <Stack
              className={cx(classes.creditsContainer, {
                [classes.creditsContainerRed]: !creditsLeft,
              })}
              spacing={2}
            >
              <Center>
                <CustomTitle
                  order={0}
                  sx={(theme) => ({
                    fontSize: rem(64),
                    color: theme.colors.blue[0],
                  })}
                >
                  {credits}
                </CustomTitle>
              </Center>
              <Center>
                <CustomText
                  variant="h4"
                  sx={(theme) => ({ color: theme.colors.blue[0] })}
                >
                  Credits
                </CustomText>
              </Center>
            </Stack>
            {!creditsLeft ? (
              <CustomText variant="body1" className={classes.disclaimer}>
                {chargeText}
              </CustomText>
            ) : (
              <CustomText variant="body1">Use credits?</CustomText>
            )}
            <Stack spacing={8}>
              <MainButton
                disabled={!creditsLeft}
                smallPadding
                sx={{ width: rem(203) }}
                onClick={() => {
                  setActiveModalIndex(1);
                  if (onSuccess) {
                    onSuccess();
                  }
                }}
              >
                Confirm
              </MainButton>
              <UnstyledButton onClick={() => setActiveModalIndex(null)}>
                <CustomText
                  variant="body2"
                  sx={(theme) => ({
                    color: theme.colors.gray[4],
                    textAlign: 'center',
                  })}
                >
                  Not right now
                </CustomText>
              </UnstyledButton>
            </Stack>
          </Stack>
        </Center>
      </Modal>
      <Modal
        opened={activeModalIndex === 1}
        onClose={() => setActiveModalIndex(null)}
        centered
        withCloseButton={false}
        classNames={{
          content: classes.modalContent,
          body: classes.modaBody,
        }}
      >
        <UnstyledButton
          className={classes.closeButton}
          onClick={() => setActiveModalIndex(null)}
        >
          <AiOutlineCloseCircle />
        </UnstyledButton>
        <Center sx={{ flexGrow: 2 }}>
          <Stack align="center">
            <CustomTitle order={1}>Confirmed!</CustomTitle>
            <CustomText variant="body1" className={classes.description}>
              We will socially enrich <span>{credits} Contacts</span>
            </CustomText>
            <Box py={8}>
              <SuccessIcon style={{ width: rem(41), height: rem(41) }} />
            </Box>
            <CustomText variant="body1" className={classes.description}>
              We're gathering the data, check back in <span>24hrs</span> to see
              the
              <br />
              recommendations!
            </CustomText>
            <MainButton
              smallPadding
              sx={{ width: rem(203) }}
              onClick={() => setActiveModalIndex(null)}
            >
              Continue
            </MainButton>
          </Stack>
        </Center>
      </Modal>
    </Box>
  );
}

export default EnrichFlow;
