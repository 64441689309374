import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  modalButton: {
    width: rem(220),
    border: `${rem(1)} solid ${theme.colors.gray[3]}`,
    borderRadius: rem(20),
    padding: `${rem(21)} ${rem(16)} ${rem(19)}`,
  },
  modalContent: {
    borderRadius: rem(20),
    minWidth: rem(503),
  },
  modaBody: {
    minHeight: rem(242),
    display: 'flex',
    padding: rem(24),
  },
}));
