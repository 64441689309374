import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Center, LoadingOverlay, Stack, rem, Group } from '@mantine/core';
import { ReactComponent as LogoIcon } from '../assets/icons/ATC-logo-colored.svg';
import CustomTitle from '../components/Typography/CustomTitle/CustomTitle';
import useJwtToken from '../hooks/useJwtToken';
import { Elements } from '@stripe/react-stripe-js';
import StripeCheckoutForm from '../components/stripe/StripeCheckoutForm';
import { loadStripe } from '@stripe/stripe-js';
import useRadarApi from '../hooks/useRadarApi';
import Snackbar from '../components/Snackbar';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../features/layoutSlice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import BasicModal from '../components/Modals/BasicModal';
import CustomText from '../components/Typography/CustomText/CustomText';
import MainButton from '../components/buttons/buttons';

import logger from '../utils/logger';
import OrgSelectionMenu from '../components/header/OrgSelectionMenu/OrgSelectionMenu';

import { stripeConfig } from '../config';

const { publicKey: stripePublicKey } = stripeConfig;

const stripePromise = loadStripe(stripePublicKey);

function CaptureCCDetailPage() {
  const { getActiveSubscriptionPlan, getStripeClientSecret } = useRadarApi();
  const {
    subscriptionStatus,
    orgId,
    loading,
    refreshToken,
    subscriptionClaim,
  } = useJwtToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showPage, setShowPage] = useState(false);
  const [formLoading, setLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [successModalOpened, setSuccessModalOpened] = useState(false);
  const [errorModalOpened, setErrorModalOpened] = useState(false);
  const [isErrorFlow, setIsErrorFlow] = useState(false); // State for error flow

  const stripeOptions = useMemo(
    () => ({
      clientSecret,
      theme: 'stripe',
    }),
    [clientSecret],
  );

  useEffect(() => {
    if (subscriptionStatus === 'ACTIVE') {
      navigate('/', { replace: true });
    }

    if (!subscriptionStatus) {
      setShowPage(true);
    }
  }, [subscriptionStatus]);

  useEffect(() => {
    if (!loading && !clientSecret) {
      getStripeClientSecret().then((res) => {
        setClientSecret(res?.clientSecret);
      });
    }
  }, [loading, clientSecret]);

  const paymentCallback = useCallback(() => {
    const tokenHasRequiredClaims = (authToken) => {
      return jwtDecode(authToken)[subscriptionClaim]?.status === 'ACTIVE';
    };

    const refreshTokenUntilValid = async () => {
      const maxAttempts = 5; // Maximum number of attempts to refresh the token
      let attempts = 0;
      let validToken = false;

      while (attempts < maxAttempts && !validToken) {
        try {
          logger.debug('Refreshing token');
          const token = await refreshToken({ ignoreCache: true });

          if (tokenHasRequiredClaims(token)) {
            logger.debug('Token has required claims');
            validToken = true;
            setLoading(false);
            setSuccessModalOpened(true);
            break;
          } else {
            // Wait for a short delay before trying again
            logger.debug(
              'Token does not have required claims, waiting before retrying',
            );
            await new Promise((resolve) => setTimeout(resolve, 5000)); // 5 second delay
            logger.debug('Retrying token refresh');
          }
        } catch (error) {
          setLoading(false);
          setErrorModalOpened(true);
          logger.error('Error refreshing token:', error);
          break;
        }
        attempts++;
      }

      if (!validToken) {
        // Handle the case where the token still does not have the required claims after maximum attempts
        setErrorModalOpened(true);
        setLoading(false);
        dispatch(
          setSnackbar({
            variant: 'error',
            description: 'Unable to update session, please try again',
          }),
        );
      }
    };

    const getActiveSubscriptionPlanFunc = async () => {
      try {
        let isActive = false;
        const endTime = Date.now() + 31000; // 31 seconds from now

        while (Date.now() < endTime && !isActive) {
          const res = await getActiveSubscriptionPlan(orgId);
          if (res) {
            isActive = true;
            logger.debug('Subscription is active, now refreshing token');
            await refreshTokenUntilValid(); // Use the new function to refresh the token
            logger.debug('Token now refreshed');
            break; // Exit the loop if subscription is active and token is refreshed
          } else {
            // Wait for 1 second before trying again
            logger.debug(
              'Subscription is not Active, waiting before retrying',
            );
            await new Promise((resolve) => setTimeout(resolve, 1000));
          }
        }

        if (!isActive) {
          // Handling the case when subscription isn't found within the time limit
          setLoading(false);
          setErrorModalOpened(true);
          logger.debug('Subscription not found ACTIVE within time limit');
          dispatch(
            setSnackbar({
              variant: 'error',
              description: 'Refresh the page to try again',
            }),
          );
        }
      } catch (error) {
        setLoading(false);
        setErrorModalOpened(true);
        logger.error('Error:', error);
        dispatch(
          setSnackbar({
            variant: 'error',
            description: 'Refresh the page to try again',
          }),
        );
      }
    };

    getActiveSubscriptionPlanFunc();
  }, [orgId]);

  const handleSuccessModalClick = useCallback(() => {
    setSuccessModalOpened(false);
    navigate('/', { replace: true });
    logger.info('Attempted to send user to /');
    navigate(0);
  }, []);

  const handleErrorModalClick = useCallback(() => {
    setErrorModalOpened(false);
  }, []);

  const subtitleText = useMemo(() => {
    const flow = searchParams.get('flow');

    if (flow === 'expired') {
      setIsErrorFlow(false); // Not an error flow
      return (
        <>
          It looks like we have had trouble collecting payment for your subscription. No biggie.
          You can update your credit card right here and get back to smooth skies.
        </>
      );
    }

    if (flow === 'trialEnd') {
      setIsErrorFlow(false); // Not an error flow
      return (
        <>
          Your Test Flight period has ended! To continue flying with ATC you’ll
          need to enter your payment details below. You'll be charged and your
          subscription will start immediately on the Business Class tier.
        </>
      );
    }

    setIsErrorFlow(true); // Error flow
    return (
      <>
        Oops, looks like there was a problem. Please contact support on
        <a href='support@airtrafficcontrol.io'>support@airtrafficcontrol.io</a>.
      </>
    );
  }, [searchParams]);

  return (
    <>
      {showPage && (
        <Center h="100vh">
          <Box
            sx={{
              '& svg': {
                stroke: 'rgb(34, 139, 230)',
              },
            }}
          >
            <LoadingOverlay visible={formLoading} />
          </Box>
          <Stack spacing={42}>
            <Stack spacing={48} align="center" w={rem(510)}>
              <Stack spacing={28} align="center">
                <LogoIcon
                  className="logo-icon"
                  style={{ width: rem(217), height: rem(72) }}
                />
                <CustomText variant="h3" sx={{ textAlign: 'center' }}>
                  {subtitleText}
                </CustomText>
                <Box sx={{position: "absolute", top: rem(32), right: rem(32)}}>
                  <Group>
                    <CustomText sx={{fontWeight: "700"}}>
                      Switch Account:
                    </CustomText>
                    <OrgSelectionMenu />
                  </Group>
                </Box>
              </Stack>

              {/* Render Elements block only if isErrorFlow is false */}
              {clientSecret && !isErrorFlow && (
                <Elements options={stripeOptions} stripe={stripePromise}>
                  <StripeCheckoutForm
                    metadata={{ OrgId: orgId }}
                    callback={paymentCallback}
                    clientSecret={clientSecret}
                    setLoading={setLoading}
                  />
                </Elements>
              )}
            </Stack>
          </Stack>
        </Center>
      )}
      <Snackbar />

      {/* Success Modal */}
      <BasicModal
        opened={successModalOpened}
        onClose={handleSuccessModalClick}
        centered
        withCloseButton={false}
      >
        <Stack spacing={32} w={rem(550)}>
          <Stack spacing={8}>
            <CustomTitle order={1} sx={{ textAlign: 'center' }}>
              Subscription Activated!
            </CustomTitle>
            <CustomText variant="h2" sx={{ textAlign: 'center' }}>
              Your Test Flight is ready for takeoff! Click continue to
              start your two week Test Flight with Air Traffic Control.
            </CustomText>
          </Stack>
          <MainButton onClick={handleSuccessModalClick}>Continue</MainButton>
        </Stack>
      </BasicModal>

      {/* Error Modal */}
      <BasicModal
        opened={errorModalOpened}
        onClose={handleErrorModalClick}
        centered
        withCloseButton={false}
      >
        <Stack spacing={32} w={rem(550)}>
          <Stack spacing={8}>
            <CustomTitle order={1} sx={{ textAlign: 'center' }}>
              Whoops! Looks like that didn’t work.
            </CustomTitle>
            <CustomText variant="h2" sx={{ textAlign: 'center' }}>
              You might want to try a different payment method or you can drop us
              a line at <a href='support@airtrafficcontrol.io'>support@airtrafficcontrol.io</a>.
            </CustomText>
          </Stack>
          <MainButton onClick={handleErrorModalClick}>Close</MainButton>
        </Stack>
      </BasicModal>
    </>
  );
}

export default withAuthenticationRequired(CaptureCCDetailPage);
