import React, { useState, useCallback } from 'react';
import { Center, Modal, rem, Stack } from '@mantine/core';
import { ReactComponent as ClipBoardIcon } from '../../assets/icons/clipboard-icon.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/icons/linkedin-icon.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download-icon.svg';
import CustomTitle from '../Typography/CustomTitle/CustomTitle';
import CustomText from '../Typography/CustomText/CustomText';
import MainButton from '../buttons/buttons';
import DoubleChoiceModal from './DoubleChoiceModal';
import { useStyle } from './DoubleChoiceModal.style';

function DownloadsMatchesFlow({
  disabled,
  onLinkedinExportClick,
  onFullReportExportClick,
}) {
  const [modalState, setModalState] = useState({
    optionsModal: false,
    finalModal: false,
  });
  const { classes } = useStyle();

  const handleFullReportExportClick = useCallback(() => {
    updateModalState('optionsModal', false);
    updateModalState('finalModal', true);

    if (onFullReportExportClick) {
      onFullReportExportClick();
    }
  });

  const handleLinkedinExportClick = useCallback(() => {
    updateModalState('optionsModal', false);
    updateModalState('finalModal', true);

    if (onLinkedinExportClick) {
      onLinkedinExportClick();
    }
  });

  const updateModalState = useCallback(
    (modalName, value) => {
      setModalState((prevState) => ({
        ...prevState,
        [modalName]: value,
      }));
    },
    [modalState],
  );

  return (
    <>
      <MainButton
        icon={<DownloadIcon style={{ width: rem(25), height: rem(25) }} />}
        reversed
        onClick={() => updateModalState('optionsModal', true)}
        disabled={disabled}
        customClassName="export-contacts__button"
      >
        Export contacts
      </MainButton>
      <DoubleChoiceModal
        opened={modalState.optionsModal}
        setOpened={() => updateModalState('optionsModal', false)}
        title={
          <>
            How would you like to export <br /> content matches?
          </>
        }
        leftButtonContent={
          <Stack align="center" spacing={16}>
            <LinkedInIcon />
            <Stack spacing={4}>
              <CustomText variant="h5">LinkedIn Custom audience</CustomText>
              <CustomText
                variant="h6"
                sx={(theme) => ({
                  fontWeight: 600,
                  color: theme.colors.gray[4],
                  textAlign: 'center',
                })}
              >
                CSV file
              </CustomText>
            </Stack>
          </Stack>
        }
        rightButtonContent={
          <Stack align="center" spacing={16}>
            <ClipBoardIcon />
            <Stack spacing={4}>
              <CustomText variant="h5">Export full Report</CustomText>
              <CustomText
                variant="h6"
                sx={(theme) => ({
                  fontWeight: 600,
                  color: theme.colors.gray[4],
                  textAlign: 'center',
                })}
              >
                CSV file
              </CustomText>
            </Stack>
          </Stack>
        }
        handleLeftButtonClick={handleLinkedinExportClick}
        handleRightButtonClick={handleFullReportExportClick}
      />
      <Modal
        opened={modalState.finalModal}
        onClose={() => updateModalState('finalModal', false)}
        centered
        withCloseButton={false}
        classNames={{
          content: classes.modalContent,
          body: classes.modaBody,
        }}
      >
        <Center sx={{ flexGrow: 2 }}>
          <Stack>
            <CustomTitle order={1} sx={{ fontSize: rem(20) }}>
              Download started
            </CustomTitle>
            <MainButton onClick={() => updateModalState('finalModal', false)}>
              Close
            </MainButton>
          </Stack>
        </Center>
      </Modal>
    </>
  );
}

export default DownloadsMatchesFlow;
