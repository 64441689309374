import React, { useState, useMemo, useCallback } from 'react';
import { Box, Flex, Stack } from '@mantine/core';
import CustomTitle from '../Typography/CustomTitle/CustomTitle';
import CustomSegmentControl from '../SegmentControl/SegmentControl';
import { useStyle } from './PlanPageComponent.style';
import PlanCard from './PlanCard/PlanCard';
import useFetchPlans from '../upgradePlanComponents/hooks/useFetchPlans';
import SkeletonContainer from '../SkeletonContainer';
import {
  calculatePercentageSavings,
  getPlanDescription,
} from '../upgradePlanComponents/utils';
import useActivePlan from '../upgradePlanComponents/hooks/useActivePlan';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../features/layoutSlice';

const PLAN_PERIODS = ['MONTHLY', 'ANNUAL'];
const PLAN_ORDER = ['ECONOMY', 'BUSINESS_CLASS', 'FIRST_CLASS']

function PlanPageComponent({ onPlanSelect, smallOffset }) {
  const [planPeriod, setPlanPeriod] = useState(PLAN_PERIODS[0]);
  const [subscriptionPlans, subscriptionPlansLoading, updateSubscriptionPlan] =
    useFetchPlans();
  const [activeSubscriptionPlan] = useActivePlan();
  const dispatch = useDispatch();

  const { classes } = useStyle();

  const handleSelection = useCallback(
    async (planData) => {
      updateSubscriptionPlan(planData?.id)
        .then(() => {
          onPlanSelect(planData);
        })
        .catch(() => {
          dispatch(
            setSnackbar({
              variant: 'error',
              description: 'Something went wrong...',
            }),
          );
        });
    },
    [onPlanSelect, updateSubscriptionPlan],
  );

  const planCardsList = useMemo(() => {
    if (!subscriptionPlans || !activeSubscriptionPlan) return;

    const elements = [];

    const subscriptionPlansByPeriod = subscriptionPlans.filter(
      (plan) => plan.paymentTerms === planPeriod,
    );

    const sortedSubscriptionPlan = []

    PLAN_ORDER.forEach(currentPlanTier => {
      sortedSubscriptionPlan.push(subscriptionPlansByPeriod.find(plan => plan?.tier === currentPlanTier))
    })

    sortedSubscriptionPlan.forEach((rawPlanData) => {
      const planData = getPlanDescription(rawPlanData, activeSubscriptionPlan);

      elements.push(
        <PlanCard
          key={planData.id}
          planData={planData}
          monthlyPeriod={planPeriod === PLAN_PERIODS[0]}
          onSelect={handleSelection}
        />,
      );
    });

    return elements;
  }, [subscriptionPlans, planPeriod, activeSubscriptionPlan, handleSelection]);

  return (
    <Box>
      <Stack spacing={smallOffset ? 8 : 27} mb={smallOffset ? 16 : 24}>
        <CustomTitle order={1}>Choose a plan that works for you</CustomTitle>
        <Flex justify="flex-end">
          <CustomSegmentControl
            value={planPeriod}
            onChange={setPlanPeriod}
            data={[
              { value: PLAN_PERIODS[0], label: 'Monthly' },
              {
                value: PLAN_PERIODS[1],
                label: `Yearly (Save ${
                  subscriptionPlansLoading
                    ? 'Loading...'
                    : subscriptionPlans ? `${calculatePercentageSavings(subscriptionPlans)}%` : 'Loading...'
                })`,
              },
            ]}
            rootClassName={classes.segmentRoot}
            indicatorClassName={classes.indicator}
          />
        </Flex>
      </Stack>
      <Flex gap={24} justify="center">
        {subscriptionPlansLoading ? (
          <SkeletonContainer
            width={365}
            height={610}
            amount={3}
            radius="1.25rem"
          />
        ) : (
          <>{planCardsList}</>
        )}
      </Flex>
    </Box>
  );
}

export default PlanPageComponent;
