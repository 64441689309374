import { createStyles, rem } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  modalContent: {
    borderRadius: rem(20),
    minWidth: rem(503),
  },
  modaBody: {
    minHeight: rem(242),
    display: 'flex',
    padding: rem(24),
  },
  disclaimer: {
    width: "60%",
    textAlign: "center"
  },
  creditsContainer: {
    width: 'fit-content',
    background: theme.colors.white[0],
    border: `${rem(1)} solid ${theme.colors.white[1]}`,
    borderRadius: rem(20),
    padding: `${rem(16)} ${rem(24)}`,
  },
  creditsContainerRed: {
    borderColor: theme.colors.red[0],
  },
  description: {
    textAlign: 'center',

    '& span': {
      fontWeight: 700,
      fontSize: rem(16),
      fontFamily: 'Manrope',
    },
  },
  descriptionRed: {
    color: theme.colors.red[0],
  },
  closeButton: {
    position: 'absolute',
    right: rem(26),
    top: rem(26),

    '& svg': {
      height: rem(23),
      width: rem(23),
    },
  },
  loadInteractionsButton: {
    position: 'relative',
    background: theme.colors.yellow[1],
    borderRadius: 100,
    padding: `${rem(8)} ${rem(24)}`,
  },
  loadInteractionsButtonDisabled: {
    background: theme.colors.gray[3],
    cursor: 'not-allowed',
  },
}));
