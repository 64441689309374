export const getPlanDescription = (
  subscriptionPlan,
  activeSubscriptionPlan,
) => {
  const planTierToTitleMap = {
    ECONOMY: 'Economy',
    BUSINESS_CLASS: 'Business Class',
    FIRST_CLASS: 'First Class',
  };

  const planTierToDescriptionMao = {
    ECONOMY: (
      <>
        Access to the basics,
        <br />
        free now and forever!
      </>
    ),
    BUSINESS_CLASS: (
      <>
        Access to all features
        <br />
        with limits.
      </>
    ),
    FIRST_CLASS: (
      <>
        Take advantage of all features and
        <br />
        boost your business.
      </>
    ),
  };

  const planDescription = {
    id: subscriptionPlan.id,
    title: planTierToTitleMap[subscriptionPlan.tier],
    description: planTierToDescriptionMao[subscriptionPlan.tier],
    price: subscriptionPlan.price,
    planList: [
      'Topic radar chart',
      'Audience relevance chart',
      'Content performance reporting',
    ],
  };

  const IndustryNewsTopicCadence = planDescription.title === 'First Class' ? 'day' : 'week';

  planDescription.planList.push(
    `${subscriptionPlan.engagementHistoryDays} Engagement History Days`,
  );
  planDescription.planList.push(
    `${subscriptionPlan.monthlyLinkedInEnrichmentCredits} Monthly LinkedIn Enrichment Credits`,
  );
  planDescription.planList.push(
    `${subscriptionPlan.monthlyTopicReportCredits} Monthly Topic reports`,
  );
  planDescription.planList.push(
    `${subscriptionPlan.monthlyPersonalizationCredits} Monthly Personalization credits`,
  );
  planDescription.planList.push(
    `${subscriptionPlan.newsTopics} Industry news topics per ${IndustryNewsTopicCadence}`,
  );
  planDescription.planList.push(
    `Competitors: ${
      subscriptionPlan.trackedCompetitorsDetailed
        ? `${subscriptionPlan.trackedCompetitorsDetailed} detailed,  `
        : ''
    } ${subscriptionPlan.trackedCompetitors} tracked`,
  );

  planDescription.planList = planDescription.planList.map((item) => ({
    title: item,
  }));

  const subscriptionPlanTierIndex = Object.keys(planTierToTitleMap).findIndex(
    (tier) => tier === subscriptionPlan.tier,
  );
  const activeSubscriptionPlanTierIndex = Object.keys(
    planTierToTitleMap,
  ).findIndex((tier) => tier === activeSubscriptionPlan?.tier);

  if (
    activeSubscriptionPlan?.tier === subscriptionPlan?.tier ||
    (activeSubscriptionPlan?.tier === null &&
      subscriptionPlan.tier === 'ECONOMY')
  ) {
    planDescription.currentPlan = true;
  } else if (subscriptionPlanTierIndex < activeSubscriptionPlanTierIndex) {
    planDescription.downgradePlan = true;
  }

  return planDescription;
};

export const calculatePercentageSavings = (subscriptionPlans) => {
  const annualPrice = subscriptionPlans.find(
    (plan) => plan.paymentTerms === 'ANNUAL' && plan.price !== 0,
  )?.price;
  const monthlyPrice = subscriptionPlans.find(
    (plan) => plan.paymentTerms === 'MONTHLY' && plan.price !== 0,
  )?.price;

  const totalAnnualFromMonthly = monthlyPrice * 12;
  const savings = totalAnnualFromMonthly - annualPrice;
  const percentageSavings = (savings / totalAnnualFromMonthly) * 100;
  return Math.round(percentageSavings);
};
