// App.jsx
import './App.css';
import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';

import { Box } from '@mantine/core';
import LandingPage from './pages/LandingPage.jsx';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import TopicsPage from './pages/TopicPage/TopicsPage.jsx';
import ContentsPage from './pages/ContentsPage/ContentsPage.jsx';
import ContactsPage from './pages/ContactsPage/ContactsPage.jsx';
import CompetitorsPage from './pages/CompetitorsPage/CompetitorsPage.jsx';
import AccountPage from './pages/AccountPage/AccountPage.jsx';
import SettingsPage from './pages/SettingsPage/SettingsPage.jsx';
import GettingStartedPage from './pages/GettingStartedPage.jsx';
import PlanPage from './pages/PlanPage.jsx';
import AuthCallbackPage from './pages/AuthCallbackPage.jsx';
import NotFoundPage from './pages/NotFoundPage.jsx';
import LogoutPage from './pages/LogoutPage.jsx';
import CompetitorsContentDetailPage from './pages/CompetitorsContentDetailPage.jsx';
// import LoginPage from "./pages/LoginPage";

import PageLoader from './components/PageLoader.jsx';
import Layout from './components/Layout.jsx';
import AuthenticationGuard from './components/AuthenticationGuard.jsx';

import 'bootstrap/dist/css/bootstrap.min.css';
import PreviewPage from './pages/PreviewPage/PreviewPage';
import CompetitorContent from './pages/CompetitorsPage/components/CompetitorContent/CompetitorContent';
import TopicsDetailPage from './pages/TopicsDetailPage/TopicsDetailPage';
import ContentsDetailPage from './pages/ContentsDetailPage/ContentsDetailPage';
import LoginPage from './pages/LoginFlow/LoginPage';
import SignupPage from './pages/LoginFlow/SignupPage';
import RefreshRoute from './components/RefreshRoute';
import HubspotContentUtilization from './pages/HubspotContentUtilization.jsx';
import CaptureCCDetailPage from './pages/CaptureCCDetailPage.jsx';
import PassportControlRedirect from './pages/PassportControlPage/components/PassportControlRedirect';
import PassportControl from './pages/PassportControlPage/PassportControlPage';
import useJwtToken from './hooks/useJwtToken.js';
import useActivePlan from './components/upgradePlanComponents/hooks/useActivePlan.js';
import useFlowRedirection, {
  REDIRECTION_OPTIONS,
} from './hooks/useFlowRedirection.js';
import useIntegrations from './hooks/useIntegrations.js';

import { appCues } from './services/appCuesService';
import { datadogConfig, appCuesConfig, redirectionConfig } from './config.js';


import logger from './utils/logger.js';

function App() {
  const { isAuthenticated, isLoading, user } = useAuth0();

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  const rootPathElement = isAuthenticated ? (
    <Layout>
      <DashboardPage />
    </Layout>
  ) : (
    <LandingPage />
  );

  const { subscriptionStatus, permissions, loading, orgId } = useJwtToken();
  const { hubspotDetails } = useIntegrations();
  const [activeSubscriptionPlan] = useActivePlan();
  const [redirectionFlow] = useFlowRedirection(
    subscriptionStatus,
    loading,
    permissions,
    activeSubscriptionPlan,
    hubspotDetails,
  );
  const navigate = useNavigate();

  useEffect(() => {
    logger.debug('App useEffect ran', {
      subscriptionStatus,
      loading,
      permissions,
      activeSubscriptionPlan,
      hubspotDetails,
      });
  }, [subscriptionStatus, loading, permissions, activeSubscriptionPlan, hubspotDetails]);

  useEffect(() => {
    switch (redirectionFlow) {
      case REDIRECTION_OPTIONS.passportControl: {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('emailVerification', 'true');
        searchParams.set('preserveLogin', 'true');

        navigate(
          `/passport-control?${searchParams.toString()}`,
          {
            replace: true,
          },
        );
        break;
      }
      case REDIRECTION_OPTIONS.contentUtilizationRedirection:
        navigate(`/hubspot-content-utilization${window.location.search}`, {
          replace: true,
        });
        break;
      case REDIRECTION_OPTIONS.holdingPatternRedirection:
          window.location.href = redirectionConfig.holdingPattern;
        break;
      case REDIRECTION_OPTIONS.ccCaptureRedirection:
        navigate(
          `/capture-cc?flow=${
            subscriptionStatus === 'PAUSED'
              ? 'trialEnd'
              : subscriptionStatus === 'PAST_DUE'
              ? 'expired'
              : 'error'
          }`,
          { replace: true },
        );
        break;
    }
  }, [
    redirectionFlow,
  ]);

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      logger.info('App useEffect ran', { isAuthenticated, isLoading, user, orgId });

      // Always check if user data is available and use appropriate identifiers
      const userId = user?.sub ?? 'anonymous';
      const userName = user?.name ?? 'anonymous';
      const userEmail = user?.email ?? 'anonymous';

      if (datadogConfig.clientToken !== undefined) {
        datadogRum.setUser({
          id: userId,
          name: userName,
          email: userEmail,
          orgId,
        });
      }

      if(appCuesConfig.accountId !== undefined) {
        appCues.setUser({
          userId,
          userName,
          userEmail,
          orgId,
        });
      }
    }
  }, [isLoading, isAuthenticated, user, orgId]);

  return (
      <Routes>
        <Route path="/" element={rootPathElement} />
        <Route
          path="/auth-callback"
          element={
            <Layout>
              <AuthCallbackPage />
            </Layout>
          }
        />
        {/* TODO: redirect to Auth0 tendant /authorize endpoint, only applicable to hosted app not dev */}
        {/* <Route path="/login"  element={<Layout><LoginPage /></Layout>} />  */}
        <Route
          path="/hubspot-content-utilization"
          element={
            <AuthenticationGuard component={React.Fragment}>
              <HubspotContentUtilization />
            </AuthenticationGuard>
          }
        />
        <Route path="/passport-control" element={<PassportControl />} />
        <Route path="/redirect" element={<PassportControlRedirect />} />
        <Route path="/capture-cc" element={<CaptureCCDetailPage />} />
        <Route
          path="/quick-start"
          element={
            <AuthenticationGuard component={Layout}>
              <PreviewPage />
            </AuthenticationGuard>
          }
        />
        <Route
          path="/contents"
          element={
            <AuthenticationGuard component={Layout}>
              <ContentsPage />
            </AuthenticationGuard>
          }
        />
        <Route
          path="/contents/detail/:contentId"
          element={
            <AuthenticationGuard component={Layout}>
              <RefreshRoute>
                <ContentsDetailPage />
              </RefreshRoute>
            </AuthenticationGuard>
          }
        />
        <Route path="/logout" element={<LogoutPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route
          path="/account"
          element={
            <AuthenticationGuard component={Layout}>
              <AccountPage />
            </AuthenticationGuard>
          }
        />
        <Route
          path="/topics"
          element={
            <AuthenticationGuard component={Layout}>
              <TopicsPage />
            </AuthenticationGuard>
          }
        />
        <Route
          path="/topics/detail/:topicId"
          element={
            <AuthenticationGuard component={Layout}>
              <RefreshRoute>
                <TopicsDetailPage />
              </RefreshRoute>
            </AuthenticationGuard>
          }
        />
        <Route
          path="/contacts"
          element={
            <AuthenticationGuard component={Layout}>
              <ContactsPage />
            </AuthenticationGuard>
          }
        />
        <Route
          path="/competitors"
          element={
            <AuthenticationGuard component={Layout}>
              <CompetitorsPage />
            </AuthenticationGuard>
          }
        >
          <Route
            path="content"
            element={
              <AuthenticationGuard component={Box}>
                <CompetitorContent />
              </AuthenticationGuard>
            }
          />
        </Route>
        <Route
          path="/competitors/content/:articleId"
          element={
            <AuthenticationGuard component={Layout}>
              <RefreshRoute>
                <CompetitorsContentDetailPage />
              </RefreshRoute>
            </AuthenticationGuard>
          }
        />
        <Route
          path="/getting-started"
          element={
            <AuthenticationGuard component={Layout}>
              <GettingStartedPage />
            </AuthenticationGuard>
          }
        />
        <Route
          path="/settings"
          element={
            <AuthenticationGuard component={Layout}>
              <SettingsPage />
            </AuthenticationGuard>
          }
        />
        <Route
          path="/plan"
          element={
            <AuthenticationGuard component={Layout}>
              <PlanPage />
            </AuthenticationGuard>
          }
        />
        <Route
          path="*"
          element={
            <AuthenticationGuard component={Layout}>
              <NotFoundPage />
            </AuthenticationGuard>
          }
        />
      </Routes>
  );
}

export default App;
