import React, { useCallback, useEffect, useMemo, useState } from 'react';
import IntegrationWrapper from '../IntegrationWrapper/IntegrationWrapper';
import ContactListsMultiSelect from './ContactListsMultiSelect';
import CustomText from '../../../Typography/CustomText/CustomText';
import useContactLists from '../hooks/useContactLists';
import useIntegrations from '../../../../hooks/useIntegrations';
import useRadarApi from '../../../../hooks/useRadarApi';
import { settings } from '../../../../config';
import DoubleChoiceModal from '../../../Modals/DoubleChoiceModal';
import {
  Box,
  Center,
  Group,
  Modal,
  Stack,
  UnstyledButton,
  rem,
} from '@mantine/core';
import { useStyle } from '../IntegrationWrapper/IntegrationWrapper.style';
import CustomTitle from '../../../Typography/CustomTitle/CustomTitle';
import MainButton from '../../../buttons/buttons';
import { ReactComponent as TickSquareIcon } from '../../../../assets/icons/tick-square-icon.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateSettingsLoading } from '../../../../features/settingsSlice';

const HubspotIntegration = ({
  withoutContactLists,
  image,
  customText,
  listIds,
  urlParams,
}) => {
  const { classes } = useStyle();
  const { getHubspotAuthURL, getHubspotContactLists } = useRadarApi();
  const { hubspotDetails, pardotDetails, loading, refetch } = useIntegrations();
  const [modalState, setModalState] = useState({
    doubleChoiceModal: false,
    editModal: false,
    importAllContactsModal: false,
  });
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const onSubmit = () => {
    setDataSubmitted(true);
  };

  const {
    contactLists: hubspotContactLists,
    selectedLists: selectedHubspotLists,
    setSelectedLists: setSelectedHubspotLists,
    patchContactLists: patchHubspotContactLists,
    setSelectAll: setSelectAllHubspot,
    showError: showHubspotError,
    lastUpdateSnapshot,
  } = useContactLists(
    hubspotDetails?.contactLists,
    getHubspotContactLists,
    hubspotDetails?.syncAllContacts,
    onSubmit,
  );

  const updateModalState = useCallback(
    (modalName, value) => {
      setModalState((prevState) => ({
        ...prevState,
        [modalName]: value,
      }));
    },
    [modalState],
  );

  const editHubspotContactLists = useCallback(
    (optionalBody) => {
      if (urlParams?.listIds?.length) {
        return patchHubspotContactLists(hubspotDetails?.id, {
          contactLists: [
            ...hubspotDetails.contactLists,
            ...selectedHubspotLists,
          ],
        });
      }
      return patchHubspotContactLists(hubspotDetails?.id, optionalBody);
    },
    [patchHubspotContactLists, hubspotDetails, selectedHubspotLists, urlParams],
  );

  const handleHubspotConnection = useCallback(async () => {
    const url = await getHubspotAuthURL({
      redirectPath: 'settings?tab=integrations',
    });
    window.location.href = url;
  }, [getHubspotAuthURL, selectedHubspotLists]);

  const handleEditButtonClick = useCallback(
    (value) => {
      if (modalState.editModal === true) {
        updateModalState('editModal', value);
        if (state?.originUrl) {
          navigate(`/${state?.originUrl}`);
        }
      } else {
        updateModalState('doubleChoiceModal', true);
      }
    },
    [modalState, state],
  );

  const handleBackButtonClick = useCallback(
    (modalValue) => {
      updateModalState('doubleChoiceModal', true);
      updateModalState(modalValue, false);

      if (modalValue === 'editModal' && urlParams?.listIds?.length) {
        if (state?.originUrl) {
          navigate(`/${state?.originUrl}`);
        }
      }
    },
    [updateModalState, urlParams, state],
  );

  const importAllContactLists = useCallback(() => {
    editHubspotContactLists({ syncAllContacts: true });
    updateModalState('importAllContactsModal', false);
  }, [editHubspotContactLists]);

  const handleContactListsSelection = useCallback(() => {
    updateModalState('doubleChoiceModal', false);
    updateModalState('editModal', true);
    setSelectAllHubspot(false);
  }, []);

  const handleImportAllContacts = useCallback(() => {
    updateModalState('doubleChoiceModal', false);
    updateModalState('importAllContactsModal', true);
    setSelectAllHubspot(true);
  }, []);

  const isLeftBoxActive = useMemo(() => {
    if (lastUpdateSnapshot) {
      return lastUpdateSnapshot?.syncAllContacts;
    }
    return hubspotDetails?.syncAllContacts;
  }, [hubspotDetails, lastUpdateSnapshot]);

  const isRightBoxActive = useMemo(() => {
    if (lastUpdateSnapshot) {
      return lastUpdateSnapshot?.contactLists?.length;
    }
    return hubspotDetails?.contactLists?.length;
  }, [hubspotDetails, lastUpdateSnapshot]);

  const integrationAllowed = useMemo(() => {
    if (!hubspotDetails?.connected && pardotDetails?.connected) {
      return false;
    }
    return true;
  }, [settings, hubspotDetails, pardotDetails]);

  useEffect(() => {
    if (listIds?.[0] && hubspotContactLists?.length > 0) {
      handleContactListsSelection();
      const splitListIds = listIds[0].split(',');

      setSelectedHubspotLists([
        ...selectedHubspotLists,
        ...hubspotContactLists.filter((list) =>
          splitListIds.includes(list.listId),
        ),
      ]);

      dispatch(updateSettingsLoading({ loading: false }));
    }
  }, [listIds, hubspotContactLists]);

  const filteredValues = useMemo(() => {
    return (selectedHubspotLists || []).filter(
      (el) =>
        !(hubspotDetails?.contactLists || []).some(
          (list) => list.listId === el.listId,
        ),
    );
  }, [selectedHubspotLists, hubspotDetails]);

  const filteredData = useMemo(() => {
    return (hubspotDetails?.availableContactLists || []).filter(
      (el) =>
        !(hubspotDetails?.contactLists || []).some(
          (list) => list.listId === el.listId,
        ),
    );
  }, [selectedHubspotLists, hubspotDetails]);

  return (
    <>
      <IntegrationWrapper
        onlyButton={!image && !customText}
        image={image}
        onConnect={handleHubspotConnection}
        connected={hubspotDetails?.connected}
        onEdit={editHubspotContactLists}
        notFinished={
          !hubspotDetails?.contactLists?.length &&
          !hubspotDetails?.syncAllContacts &&
          !dataSubmitted
        }
        loading={loading}
        disabledText={
          !settings.integrations.hubspot || !integrationAllowed
            ? 'Connect'
            : null
        }
        handleEditButtonClickExternal={handleEditButtonClick}
        handleBackButtonClick={() => handleBackButtonClick('editModal')}
        editModalOpenedExternal={modalState.editModal}
        customRefetch={refetch}
        snackbarText={{
          success: (
            <>
              Your changes have been successfully updated.
              <br />
              It will take upto 24hrs before the list details will be synced
            </>
          ),
        }}
        customInputComponent={
          !withoutContactLists && (
            <ContactListsMultiSelect
              onChange={setSelectedHubspotLists}
              data={filteredData}
              value={filteredValues}
              showError={showHubspotError && 'Select at least one list'}
              urlParams={urlParams}
            />
          )
        }
        allowReconnect
      >
        {customText && (
          <CustomText
            variant="body2"
            sx={(theme) => ({
              color: theme.colors.gray[4],
              textAlign: 'center',
            })}
          >
            Connects with your content and contacts
          </CustomText>
        )}
      </IntegrationWrapper>
      <DoubleChoiceModal
        opened={modalState.doubleChoiceModal}
        setOpened={() => updateModalState('doubleChoiceModal', false)}
        title={
          <>
            How would you like to select <br /> your contacts?
          </>
        }
        leftBoxClassName={isLeftBoxActive ? classes.selectedModalButton : null}
        rightBoxClassName={
          isRightBoxActive ? classes.selectedModalButton : null
        }
        leftButtonContent={
          <Box h={rem(100)}>
            <Center h="100%">
              <Stack spacing={0}>
                <CustomText variant="h5">Import all contacts</CustomText>
                <Center>
                  {isLeftBoxActive ? (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: rem(12),
                        right: rem(12),
                      }}
                    >
                      <TickSquareIcon />
                    </Box>
                  ) : null}
                </Center>
              </Stack>
            </Center>
          </Box>
        }
        rightButtonContent={
          <Box h={rem(100)}>
            <Center h="100%">
              <Stack spacing={0}>
                <CustomText variant="h5">Select a contact list</CustomText>
                <Center>
                  {isRightBoxActive ? (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: rem(12),
                        right: rem(12),
                      }}
                    >
                      <TickSquareIcon />
                    </Box>
                  ) : null}
                </Center>
              </Stack>
            </Center>
          </Box>
        }
        handleLeftButtonClick={handleImportAllContacts}
        handleRightButtonClick={handleContactListsSelection}
      />
      <Modal
        opened={modalState.importAllContactsModal}
        onClose={() => updateModalState('importAllContactsModal', false)}
        centered
        withCloseButton={false}
        classNames={{
          content: classes.modalContent,
          body: classes.modaBody,
        }}
      >
        <Center sx={{ flexGrow: 2 }}>
          <Stack spacing={32} align="center">
            <CustomTitle
              order={1}
              sx={{ fontSize: rem(20), textAlign: 'center' }}
            >
              Are you sure you want to import all your contacts?
            </CustomTitle>
            <Group>
              <MainButton
                onClick={importAllContactLists}
                sx={{ width: rem(203), height: rem(32) }}
              >
                I'm sure
              </MainButton>
              <UnstyledButton
                className={classes.backButton}
                onClick={() => handleBackButtonClick('importAllContactsModal')}
              >
                Back
              </UnstyledButton>
            </Group>
          </Stack>
        </Center>
      </Modal>
    </>
  );
};

export default HubspotIntegration;
