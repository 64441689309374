import React from 'react';
import { Flex, Modal, rem, Stack, UnstyledButton } from '@mantine/core';
import CustomTitle from '../Typography/CustomTitle/CustomTitle';
import { useStyle } from './DoubleChoiceModal.style';

function DoubleChoiceModal({
  opened,
  setOpened,
  title,
  leftButtonContent,
  rightButtonContent,
  handleLeftButtonClick,
  handleRightButtonClick,
  leftBoxClassName,
  rightBoxClassName
}) {
  const { classes, cx } = useStyle();

  return (
    <>
      <Modal
        opened={opened}
        centered
        withCloseButton={false}
        size="auto"
        classNames={{
          content: classes.modalContent,
          body: classes.modaBody,
        }}
        onClose={() => setOpened(false)}
      >
        <Stack spacing={16}>
          <CustomTitle
            order={1}
            sx={{ fontSize: rem(20), textAlign: 'center' }}
          >
            {title}
          </CustomTitle>
          <Flex gap={16} justify="center">
            <UnstyledButton
              className={cx(classes.modalButton, leftBoxClassName)}
              onClick={handleLeftButtonClick}
            >
              {leftButtonContent}
            </UnstyledButton>
            <UnstyledButton
              className={cx(classes.modalButton, rightBoxClassName)}
              onClick={handleRightButtonClick}
            >
              {rightButtonContent}
            </UnstyledButton>
          </Flex>
        </Stack>
      </Modal>
    </>
  );
}

export default DoubleChoiceModal;
