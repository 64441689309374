import React, { useState, useEffect } from 'react';
import { Box } from '@mantine/core';
import { useDispatch } from 'react-redux';
import PlanPageComponent from '../components/PlanPageComponent/PlanPageComponent';
import BasicModal from '../components/Modals/BasicModal';
import UpdatePlanPayment from '../components/upgradePlanComponents/paymentComponents/UpgradePlanPayment/UpdatePlanPayment';
import { useStyle } from './PlanPage.style';
import PaymentModalWrapper from '../components/upgradePlanComponents/PaymentModalWrapper';
import { setActiveLinkKey, setHeaderTitle } from '../features/layoutSlice';
import SuccessfulPaymentModal from '../components/Modals/SuccessfulPaymentModal';

function PlanPage() {
  const [paymentModalIndex, setPaymentModalIndex] = useState(null);
  const [selectedPlanData, setSelectedPlanData] = useState({});

  const dispatch = useDispatch();
  const { classes } = useStyle();

  useEffect(() => {
    dispatch(
      setHeaderTitle({
        headerTitle: 'Upgrade',
      }),
    );
    dispatch(
      setActiveLinkKey({
        activeLinkKey: 'Upgrade',
      }),
    );
  }, []);

  return (
    <Box>
      <PlanPageComponent
        onPlanSelect={(data) => {
          setSelectedPlanData(data);
          setPaymentModalIndex(1);
        }}
      />
      <BasicModal
        withCloseButton={false}
        opened={paymentModalIndex === 0}
        onClose={() => setPaymentModalIndex(null)}
        bodyClassName={classes.modalBody}
      >
        <PaymentModalWrapper onClose={() => setPaymentModalIndex(null)}>
          <UpdatePlanPayment
            title={selectedPlanData.title}
            planList={selectedPlanData.planList}
            price={selectedPlanData.price}
            period={selectedPlanData.period}
            onSubmit={() => setPaymentModalIndex(1)}
          />
        </PaymentModalWrapper>
      </BasicModal>
      <SuccessfulPaymentModal
        withoutTitle
        paymentModalIndex={paymentModalIndex}
        setPaymentModalIndex={setPaymentModalIndex}
        content={
          <>
            You will shortly receive an email notification <br /> about this
            subscription change
          </>
        }
      />
    </Box>
  );
}

export default PlanPage;
